//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ColorTheme from './ColorTheme'
export default {
  name: 'Colors',
  components: { ColorTheme }
}
