//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rgbToHex } from '@coreui/utils/src'
export default {
  name: 'ColorView',
  data () {
    return {
      bgColor: 'rgb(255, 255, 255)'
    }
  },
  computed: {
    hexColor () {
      return rgbToHex(this.bgColor)
    }
  },
  methods: {
    setColor () {
      const elem = this.$parent.$el.children[0]
      const color = window.getComputedStyle(elem).getPropertyValue('background-color')
      this.bgColor = color || this.bgColor
    }
  },
  mounted () {
    this.setColor()
  }
}
