//
//
//
//
//
//
//
//
//
//
//

import ColorView from './ColorView'
export default {
  name: 'ColorTheme',
  components: { ColorView },
  props: {
    color: String,
  }
}
